import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import Navbar from "./Navbar"
import Notification from "./Notification"
import classNames from "classnames"
import SEO from "./SEO"
import ProgressBar from "../Elements/ProgressBar"
import Container from "./Container"
import Footer from "./Footer"

import { isBrowser, isObjectEmpty } from "../../services/general"
import {
  GATSBY_IS_AUTH_ACTIVATED,
  GATSBY_ORDER_AS_GUEST_KEY,
  GATSBY_ORDER_AS_GUEST_VALUE,
} from "gatsby-env-variables"
import { hasSignedInUser } from "../Auth/services/user"
import { navigate } from "gatsby"
import { getSignedInUser } from "../Auth/services/user"

/**
 * @param {string} seoTitle string
 * @param {string} title string
 * @param {string} subtitle string
 * @param {array} path array
 * @param {object} display settings to display footer/help banner
 * @param {} children
 */

const Layout = ({
  children,
  title,
  subtitle,
  seoTitle,
  currentStep,
  totalSteps,
  display = {
    footer: true,
    helpCenterBanner: true,
    bottomMargin: true,
    progressBar: true,
  },
  path,
  isPrivate,
  pageContext,
}) => {
  let pathArray = path ? path.split("/") : []
  let lastScrollTop = 0
  let [isScrollingDown, setIsScrollingDown] = useState(false)
  let { userData } = getSignedInUser()

  const handleScrolling = (e) => {
    let st = window.scrollY

    if (st > lastScrollTop) {
      setIsScrollingDown(true)
    } else {
      setIsScrollingDown(false)
    }

    lastScrollTop = st <= 0 ? 0 : st
  }

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleScrolling(e))

    return () => {
      window.removeEventListener("scroll", (e) => handleScrolling(e))
    }
  }, [isBrowser() && window])

  useEffect(() => {
    if (GATSBY_IS_AUTH_ACTIVATED && isPrivate) {
      let value

      if (isBrowser()) {
        value = window.sessionStorage.getItem(GATSBY_ORDER_AS_GUEST_KEY)
      }

      // if user is signed in and not a guest
      if (!hasSignedInUser() && value !== GATSBY_ORDER_AS_GUEST_VALUE)
        return navigate("/sign-in")

      // if user is signed in but not yet enrolled
      if (isObjectEmpty(userData)) {
        return navigate("/patient/enroll")
      }
    }
  }, [])

  return (
    <Fragment>
      <SEO title={seoTitle} />
      <div
        style={{
          top: isScrollingDown ? "-100%" : "0%",
          position: "sticky",
          width: "100%",
          zIndex: 30,
          transition: "top .3s ease",
        }}
      >
        <Notification />
        <Navbar path={pathArray} />
      </div>
      {!!currentStep && !!totalSteps && display?.progressBar === true ? (
        <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
      ) : null}
      {title && (
        <Container isCentered>
          <h2
            className={classNames(
              "has-text-centered mb-0 has-text-primary mx-0-mobile mt-2",
              {
                "pb-0": !subtitle,
                "pb-2": !!subtitle,
              }
            )}
          >
            {title}
            <h4 className="subtitle has-text-grey has-text-centered my-1  mx-0-mobile">
              {subtitle}
            </h4>
          </h2>
        </Container>
      )}

      <main className={classNames({ "mb-3": display?.bottomMargin })}>
        {children}
      </main>
      {/* {display.helpCenterBanner && (
        <HelpCenterBanner module={pageContext?.module?.name} />
      )} */}
      {display.footer && <Footer />}
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoTitle: PropTypes.string,
  title: PropTypes.string,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  subtitle: PropTypes.string,
  path: PropTypes.array,
}

export default Layout
