import React, { useState } from "react"
import moment from "moment"
import classNames from "classnames"
import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"

import styles from "./utils/layout.module.scss"
import {
  hasSeenNotification,
  setNotificationAsSeen,
} from "./services/notification"
import {
  GATSBY_WEBSITE_URL,
  GATSBY_SAXENDA_CONTACT_NUMBER,
  GATSBY_ORDER_AS_GUEST,
  GATSBY_ORDER_AS_GUEST_KEY,
  GATSBY_ORDER_AS_GUEST_VALUE,
  GATSBY_AUTH_NOTIFICATION,
} from "gatsby-env-variables"
import { isBrowser } from "../../services/general"

const Notification = ({ pageContext }) => {
  let dateToday = moment().format("YYYY-MM-DD")
  const [notificationHidden, setNotificationHidden] = useState(false)
  const hasSeenNotificationMessage = hasSeenNotification()

  // const { loading, error, data } = useQuery(
  //   gql`
  //     query TodaysDate($holiday_date: date!, $domain: jsonb) {
  //       holiday(
  //         where: {
  //           holiday_date: { _eq: $holiday_date }
  //           domains: { _contains: $domain }
  //         }
  //       ) {
  //         name
  //         holiday_date
  //         short_message
  //       }
  //     }
  //   `,
  //   {
  //     variables: {
  //       holiday_date: dateToday,
  //       domain: GATSBY_WEBSITE_URL,
  //     },
  //   }
  // )

  //if (loading) return null
  //if (error) return null

  let isOrderingAsGuest = false
  if (GATSBY_ORDER_AS_GUEST) {
    if (isBrowser()) {
      isOrderingAsGuest =
        window.sessionStorage.getItem(GATSBY_ORDER_AS_GUEST_KEY) ===
        GATSBY_ORDER_AS_GUEST_VALUE
    }
  }

  let notificationMessage = null

  let defaultBannerMessage = null
  if (isOrderingAsGuest) {
    defaultBannerMessage = `The Order as Guest page will only be available until ${GATSBY_AUTH_NOTIFICATION}. If you experience trouble signing in, please contact us at`
  }

  notificationMessage = (
    <span className="">
      {notificationMessage || ""}{" "}
      <span>
        We're happy to address your questions. Get answers immediately from our{" "}
        <a href="/help-center" className="has-text-weight-bold is-underlined">
          Help Center
        </a>{" "}
        or contact us at{" "}
        <a href={`tel:${GATSBY_SAXENDA_CONTACT_NUMBER}`}>
          {GATSBY_SAXENDA_CONTACT_NUMBER}
        </a>
        .
      </span>
    </span>
  )

  if (notificationMessage && !hasSeenNotificationMessage)
    return (
      <div
        className={classNames(
          "notification is-warning",
          {
            "is-hidden": notificationHidden,
          },
          styles["notification"]
        )}
      >
        <button
          aria-label="Delete Button"
          className="delete"
          onClick={() => {
            setNotificationHidden(true)
            setNotificationAsSeen()
          }}
        ></button>
        <div className="has-text-centered">{notificationMessage}</div>
      </div>
    )

  return null
}

export default Notification
