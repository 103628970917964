import React, { Fragment, useState } from "react"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"
import Notification from "./Notification"

import styles from "./utils/layout.module.scss"
import {
  faBars,
  faPills,
  faStethoscope,
  faHandHoldingMedical,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getSignedInUser, hasSignedInUser } from "../Auth/services/user"
import Button from "../Elements/Button"
import { handleSignOut } from "../Auth/services/signout"
import AuthActions from "./Navbar/AuthActions"
import AuthActionsMobile from "./Navbar/AuthActionsMobile"
import { GATSBY_IS_AUTH_ACTIVATED } from "gatsby-env-variables"

const Navbar = () => {
  const data = useStaticQuery(graphql`
    {
      logoLong: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      saxenda: file(relativePath: { eq: "logos/saxenda.png" }) {
        childImageSharp {
          fixed(height: 34) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const logoLong = data.logoLong.childImageSharp.fixed
  const saxenda = data.saxenda.childImageSharp.fixed

  const [isMenuActive, setMenuActive] = useState(false)

  const handleMenuActive = () => setMenuActive(!isMenuActive)
  const { userData, authUser } = getSignedInUser()
  const user = authUser

  const Logo = () => {
    return (
      <Link to="/">
        <div className={classNames(styles["navbar__brandLogos"])}>
          <Img fixed={logoLong} />
        </div>
      </Link>
    )
  }
  let newStyle = {
    top: 0,
    position: "fixed",
  }

  return (
    <Fragment>
      <nav
        className={classNames("navbar is-light is-flex", {
          "has-background-white": !isMenuActive,
        })}
        style={{ backgroundColor: isMenuActive ? "rgba(0,0,0,0)" : "#e6f0f0" }}
      >
        <div
          className="is-flex is-flex-direction-column"
          style={{ width: "100%" }}
        >
          <div className="is-flex">
            {!isMenuActive && (
              <div className={classNames("ml-1", styles["navbar__brand"])}>
                <Logo />
                <div className={styles["navbar__burgerContainer"]}>
                  {GATSBY_IS_AUTH_ACTIVATED && (
                    <div className="is-hidden-desktop">
                      <AuthActionsMobile
                        user={user}
                        userData={userData}
                        isMenuActive={isMenuActive}
                      />
                    </div>
                  )}
                  <a
                    onClick={handleMenuActive}
                    aria-label="menu"
                    aria-expanded="false"
                    className={classNames("icon is-clickable")}
                    role="button"
                    onKeyDown={(event) => {
                      if (event.key === "Enter") handleMenuActive()
                    }}
                    tabIndex={0}
                  >
                    <FontAwesomeIcon
                      className={classNames("has-text-primary", "is-active")}
                      icon={faBars}
                    />
                  </a>
                </div>
              </div>
            )}
            <div
              className={classNames("navbar-menu", styles["navbar__menu"], {
                "is-active": isMenuActive,
              })}
              style={!isMenuActive ? {} : newStyle}
            >
              {isMenuActive && (
                <button
                  className="delete is-absolute is-top is-right m-1"
                  onClick={() => {
                    setMenuActive(false)
                  }}
                ></button>
              )}

              <div
                className={classNames(
                  "navbar-end my-1",
                  styles["navbar__alignCenter"]
                )}
              >
                <div className="is-flex is-justify-content-center">
                  <Img fixed={saxenda} className="is-hidden-desktop my-1" />
                </div>
                {!hasSignedInUser() && (
                  <>
                    <a
                      href={"/patient"}
                      className={classNames(
                        "navbar-item has-text-primary",
                        styles["navbar__menuItem"]
                      )}
                      onClick={() => setMenuActive(false)}
                    >
                      <FontAwesomeIcon
                        icon={faHandHoldingMedical}
                        className="fa-fw is-hidden-desktop mr-1"
                        size="lg"
                      />
                      Patient Enrollment
                    </a>
                    <a
                      href={"/doctor"}
                      className={classNames(
                        "navbar-item has-text-primary",
                        styles["navbar__menuItem"]
                      )}
                      onClick={() => setMenuActive(false)}
                    >
                      <FontAwesomeIcon
                        icon={faStethoscope}
                        className="fa-fw is-hidden-desktop mr-1"
                        size="lg"
                      />
                      Doctor Enrollment
                    </a>
                    <a
                      href={"/epharmacy/upload"}
                      className={classNames(
                        "navbar-item has-text-primary",
                        styles["navbar__menuItem"]
                      )}
                      onClick={() => setMenuActive(false)}
                    >
                      <FontAwesomeIcon
                        icon={faPills}
                        className="fa-fw is-hidden-desktop mr-1"
                        size="lg"
                      />
                      Order Medicines
                    </a>
                  </>
                )}
                <a
                  href="/help-center"
                  className={classNames(
                    "navbar-item is-hidden-desktop",
                    styles["navbar__menuItemSmall"]
                  )}
                >
                  Help Center
                </a>

                <a
                  href="/terms-and-conditions"
                  className={classNames(
                    "navbar-item is-hidden-desktop",
                    styles["navbar__menuItemSmall"]
                  )}
                >
                  Terms and Conditions
                </a>

                <a
                  href="/privacy-policy"
                  className={classNames(
                    "navbar-item is-hidden-desktop",
                    styles["navbar__menuItemSmall"]
                  )}
                >
                  Privacy Policy
                </a>
                {!!hasSignedInUser() && (
                  <div className="is-hidden-desktop navbar-item">
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        handleSignOut({ redirect: true })
                      }}
                      isFullwidth
                    >
                      SIGN OUT
                    </Button>
                  </div>
                )}
              </div>
            </div>
            {GATSBY_IS_AUTH_ACTIVATED && (
              <AuthActions
                user={user}
                userData={userData}
                handleSignOut={() => handleSignOut({ redirect: true })}
              />
            )}
          </div>
        </div>
      </nav>

      <div
        role="button"
        tabIndex={0}
        aria-label="Navbar Background"
        onKeyDown={(event) => {
          if (event.key === "Enter") setMenuActive(false)
        }}
        className={classNames({
          [styles["navbar__backgroundIsActive"]]: isMenuActive,
        })}
        onClick={() => setMenuActive(false)}
      />
    </Fragment>
  )
}

export default Navbar
