import React, { useEffect, useRef } from "react"

const ProgressBar = ({ currentStep, totalSteps }) => {
  const progressRef = useRef()
  let stepValue = currentStep - 1

  const handleProgressAnimation = () => {
    progressRef.current.value = stepValue += 1
  }

  useEffect(() => {
    handleProgressAnimation()
  }, [])

  return (
    <progress
      className="progress is-yellow"
      ref={progressRef}
      value={stepValue}
      max={totalSteps}
    />
  )
}

export default ProgressBar
