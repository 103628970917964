import firebase from "firebase"
import { navigate } from "gatsby"
import { hasSignedInUser } from "./user"
import { isBrowser } from "services/general"

export const handleSignOut = ({ redirect }) => {
  try {
    firebase.auth().signOut()
    if (isBrowser()) {
      sessionStorage.removeItem("userData")
      sessionStorage.removeItem("addresses")
      sessionStorage.removeItem("contextState")
      sessionStorage.removeItem(hasSignedInUser())
    }
    if (!!redirect) {
      navigate("/", { replace: true })
    }

    if (isBrowser()) window.location.reload()
  } catch (error) {
    console.error("Sign Out Error", error)
  }
}
