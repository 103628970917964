import React from "react"
import Img from "gatsby-image"

import Container from "layout/Container"
import { graphql, Link, useStaticQuery } from "gatsby"
import sitemap from "./utils/sitemap.json"
import classNames from "classnames"

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const data = useStaticQuery(graphql`
    {
      logoLong: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      nordisk: file(relativePath: { eq: "logos/nordisk.png" }) {
        childImageSharp {
          fixed(height: 64) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const logoLong = data.logoLong.childImageSharp.fixed
  const nordisk = data.nordisk.childImageSharp.fixed

  return (
    <footer className="footer">
      <Container isCentered mobile={12} tablet={10} desktop={12} fullhd={10}>
        <div className="columns centered px-3 px-0-mobile">
          <div className="column is-6 pl-0">
            <div style={{ height: "64px" }} className="is-relative">
              <Link to="/" className="is-absolute is-bottom">
                <Img fixed={logoLong} />
              </Link>
            </div>

            <p className="footer-helper mt-1">
              MedGrocer's digitally-enabled corporate healthcare solutions and
              patient programs empower our customers to get their medicines,
              vaccines, and teleconsultations conveniently, cost-effectively,
              and intelligently.
            </p>
            <p className="footer-helper py-1 help">
              Every year, we serve over one million Filipinos either directly or
              through partnerships with employers and pharmaceutical companies.
            </p>

            <p className="footer-helper pt-1 pb-2">
              © {currentYear} · MedGrocer (MG Health Solutions Inc.)
            </p>
          </div>
          <div className="column is-6 pl-0-mobile pl-1">
            <Img fixed={nordisk} />
            <p className="footer-helper mt-1">
              We are a global healthcare company, founded in 1923 and
              headquartered just outside Copenhagen, Denmark.
            </p>
            <p className="footer-helper py-1">
              Our purpose is to drive change to defeat diabetes and other
              serious chronic diseases such as obesity, and rare blood and rare
              endocrine diseases.
            </p>

            <p className="footer-helper">
              We do so by pioneering scientific breakthroughs, expanding access
              to our medicines and working to prevent and ultimately cure the
              diseases we treat.
            </p>
            <div className="columns mx-0 mt-2">
              {sitemap.map((section, index) => (
                <div
                  className={classNames(section.classnames, "column is-6 pl-0")}
                  key={index}
                >
                  <h3 className="mt-0 has-text-primary">{section.section}</h3>
                  {section.links.map((link) => (
                    <Link
                      to={`${link.url}`}
                      className="footer-helper has-text-dark has-text-secondary is-underlined"
                      key={link.url}
                      style={{ display: "block" }}
                    >
                      {link.label}
                    </Link>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
